import { flatten, xorBy } from 'lodash';
import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import qs from 'qs';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const PeriodContext = createContext(null);

const ConsumePeriod = PeriodContext.Consumer;

const periodFormat = 'MMM DD, YYYY';

const lastXDaysPeriod = (days, t) => {
  const from = dayjs().add(-days, 'day').startOf('day');
  const to = dayjs().add(-1, 'day').endOf('day');
  const fromPrev = from.add(-days, 'day').startOf('day');
  const toPrev = to.add(-days, 'day').endOf('day');

  console.log('toprev', toPrev, to);
  return {
    key: `last${days}Days`,
    label: t('lastDaysWithAmount', { amount: days }),
    from: from.unix(),
    to: to.unix(),
    formatted: `${from.format(periodFormat)} - ${to.format(periodFormat)}`,
    labelPrev: `${days} days after last ${days} days`,
    fromPrev: fromPrev.unix(),
    toPrev: toPrev.unix(),
    formattedPrev: `${fromPrev.format(periodFormat)} - ${toPrev.format(periodFormat)}`,
    days,
  };
};

const useProvidePeriod = () => {
  const { t } = useTranslation();

  const periods = useMemo(() => [
    {
      key: 'today',
      label: t('today'),

      from: dayjs().startOf('day').unix(),
      to: dayjs().endOf('day').unix(),

      formatted: dayjs().format(periodFormat),

      labelPrev: t('yesterday'),

      fromPrev: dayjs().add(-1, 'day').startOf('day').unix(),
      toPrev: dayjs().add(-1, 'day').endOf('day').unix(),

      formattedPrev: dayjs().add(-1, 'day').startOf('day').format(periodFormat),

      days: 1,
    },
    {
      key: 'yesterday',
      label: t('yesterday'),

      from: dayjs().add(-1, 'day').startOf('day').unix(),
      to: dayjs().add(-1, 'day').endOf('day').unix(),

      formatted: dayjs().add(-1, 'day').format(periodFormat),

      labelPrev: 'Day after yesterday',

      fromPrev: dayjs().add(-2, 'day').startOf('day').unix(),
      toPrev: dayjs().add(-2, 'day').endOf('day').unix(),

      formattedPrev: dayjs().add(-2, 'day').startOf('day').format(periodFormat),

      days: 1,
    },
    {
      key: 'thisWeek',
      label: t('thisWeek'),

      from: dayjs().startOf('week').unix(),
      to: dayjs().endOf('day').unix(),

      formatted: `${dayjs().startOf('week').format(periodFormat)} - Today`,

      labelPrev: 'Last week',

      fromPrev: dayjs().add(-1, 'week').startOf('week').unix(),
      toPrev: dayjs().add(-1, 'week').endOf('day').unix(),

      formattedPrev: `${dayjs().add(-1, 'week').startOf('week').format(periodFormat)} - ${dayjs().add(-1, 'week').endOf('day').format(periodFormat)}`,

      days: dayjs().endOf('day').diff(dayjs().startOf('week'), 'day'),
    },
    {
      key: 'lastWeek',
      label: t('lastWeek'),

      from: dayjs().add(-1, 'week').startOf('week').unix(),
      to: dayjs().add(-1, 'week').endOf('week').unix(),

      formatted: `${dayjs().add(-1, 'week').startOf('week').format(periodFormat)} - ${dayjs().add(-1, 'week').endOf('week').format(periodFormat)}`,

      labelPrev: 'Week after last week',

      fromPrev: dayjs().add(-2, 'week').startOf('week').unix(),
      toPrev: dayjs().add(-2, 'week').endOf('week').unix(),

      formattedPrev: `${dayjs().add(-2, 'week').startOf('week').format(periodFormat)} - ${dayjs().add(-2, 'week').endOf('week').format(periodFormat)}`,

      days: dayjs().add(-1, 'week').endOf('week').diff(dayjs().add(-1, 'week').startOf('week'), 'day'),
    },
    lastXDaysPeriod(7, t),
    lastXDaysPeriod(28, t),
    lastXDaysPeriod(30, t),
    lastXDaysPeriod(90, t),
    {
      key: 'last12Months',
      label: t('lastMonthsWithAmount', { amount: 12 }),

      from: dayjs().add(-1, 'year').startOf('day').unix(),
      to: dayjs().endOf('day').unix(),

      formatted: `${dayjs().add(-1, 'year').format(periodFormat)} - ${dayjs().endOf('day').format(periodFormat)}`,

      labelPrev: '12 months after last 12 months',

      fromPrev: dayjs().add(-2, 'year').add(-1, 'day').startOf('day')
        .unix(),
      toPrev: dayjs().add(-1, 'year').add(-1, 'day').endOf('day')
        .unix(),

      formattedPrev: `${dayjs().add(-2, 'year').add(-1, 'day').startOf('day')
        .format(periodFormat)} - ${dayjs().add(-1, 'year').add(-1, 'day').endOf('day')
        .format(periodFormat)}`,

      days: dayjs().endOf('day').diff(dayjs().add(-1, 'year').startOf('day'), 'day'),
    },
    {
      key: 'lastCalendarYear',
      label: t('lastCalendarYear'),

      from: dayjs().add(-1, 'year').startOf('year').unix(),
      to: dayjs().add(-1, 'year').endOf('year').unix(),

      formatted: `${dayjs().add(-1, 'year').startOf('year').format(periodFormat)} - ${dayjs().add(-1, 'year').endOf('year').format(periodFormat)}`,

      labelPrev: 'Year after last calendar year',

      fromPrev: dayjs().add(-2, 'year').startOf('year').unix(),
      toPrev: dayjs().add(-2, 'year').endOf('year').unix(),

      formattedPrev: `${dayjs().add(-2, 'year').startOf('year').format(periodFormat)} - ${dayjs().add(-2, 'year').endOf('year').format(periodFormat)}`,

      days: dayjs().add(-1, 'year').endOf('year').diff(dayjs().add(-1, 'year').startOf('year'), 'day'),
    },
    {
      key: 'thisYear',
      label: t('thisYear'),

      from: dayjs().startOf('year').unix(),
      to: dayjs().endOf('day').unix(),

      formatted: `${dayjs().startOf('year').format(periodFormat)} - ${dayjs().endOf('day').format(periodFormat)}`,

      labelPrev: 'Last year',

      fromPrev: dayjs().add(-1, 'year').startOf('year').unix(),
      toPrev: dayjs().add(-1, 'year').endOf('day').unix(),

      formattedPrev: `${dayjs().add(-1, 'year').startOf('year').format(periodFormat)} - ${dayjs().add(-1, 'year').endOf('day').format(periodFormat)}`,

      days: dayjs().endOf('day').diff(dayjs().startOf('year'), 'day'),
    },
  ], []);

  console.log('PERIODS', periods);

  const [period, setPeriod] = useState(periods.find(({ key }) => key === 'last28Days'));

  return {
    period,
    periods,
    setPeriod,
  };
};

const ProvidePeriod = ({ children }) => {
  const period = useProvidePeriod();
  return (
    <PeriodContext.Provider value={period}>
      {children}
    </PeriodContext.Provider>
  );
};

const usePeriod = () => useContext(PeriodContext);

export {
  ProvidePeriod,
  ConsumePeriod,
  usePeriod,
};

import { isEmpty } from 'lodash';
import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../context/auth';
import LoadingScreen from './LoadingScreen';

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  if (auth.userIsLoading) return <LoadingScreen />;
  return (
    <Route
      {...rest}
      render={({ location }) => (auth.user ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default PrivateRoute;

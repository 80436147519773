import React, { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import DateRangePicker from 'react-daterange-picker';
import { useTranslation } from 'react-i18next';
import { usePeriod } from '../context/period';
import ButtonText from '../atoms/ButtonText';
import { ReactComponent as ChevronDown } from '../assets/images/chevron-down-outlined-medium-onbackground.svg';
import '../calendar.css';

const PeriodListItem = ({ label, ...props }) => (
  <PeriodButton type="button" {...props}>
    <span>{label}</span>
  </PeriodButton>
);

const PeriodButton = styled.button`
  display: flex;
  padding: 8px 120px 8px 16px;
  background: none;
  border: none;
  color: ${({ theme, active }) => (active ? theme.primary : theme.onBackground)};
  ${({ theme }) => `
    ${theme.textS}
    font-family: ${theme.primaryFontMedium};
  `}
`;

export default () => {
  const { t } = useTranslation();
  const [periodSelectorOpen, setPeriodSelectorStatus] = useState(false);
  const [caldenarOpen, toggleCalendar] = useState(false);
  const [calendarValue, onCalendarValueChange] = useState(null);

  const {
    periods,
    period: appliedPeriod,
    setPeriod: applyPeriod,
  } = usePeriod();

  const [tmpDate, setTmpDate] = useState(appliedPeriod);

  const togglePeriodSelector = useCallback(() => {
    setPeriodSelectorStatus(!periodSelectorOpen);
    if (caldenarOpen) toggleCalendar(false);
    if (calendarValue) onCalendarValueChange(null);
  }, [caldenarOpen, calendarValue, periodSelectorOpen]);

  return (
    <Container>
      <Head>
        <PeriodLabel>
          {appliedPeriod.label}
        </PeriodLabel>
        <Popover
          isOpen={periodSelectorOpen}
          positions={['bottom', 'top', 'left', 'right']}
          align="end"
          onClickOutside={togglePeriodSelector}
          content={(
            <SelectorContainer>
              {!caldenarOpen ? (
                <>
                  <ComparisonDate>
                    <PeriodLabel>
                      {t('willBeComparedWith')}
                    </PeriodLabel>
                    {/* <PeriodLabel>
                      {tmpDate.labelPrev}
                    </PeriodLabel> */}
                    <ComprasionDateFormatted>
                      {tmpDate.formattedPrev}
                    </ComprasionDateFormatted>
                  </ComparisonDate>
                  <List>
                    {periods.map((period) => (
                      <PeriodListItem
                        active={period.key === appliedPeriod.key}
                        key={period.key}
                        label={period.label}
                        onMouseOver={() => setTmpDate(period)}
                        // onMouseOut={() => setTmpDate({})}
                        onClick={() => {
                          setPeriodSelectorStatus(false);
                          applyPeriod(period);
                        }}
                      />
                    ))}
                  </List>
                  <PeriodListItem
                    active={appliedPeriod.key === 'custom'}
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                    label={`${t('custom')}...`}
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      // togglePeriodSelector(false);
                      toggleCalendar(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <DateRangePicker
                    onSelect={onCalendarValueChange}
                    value={calendarValue}
                  />
                  <DatePickerOptions>
                    <ButtonText onClick={togglePeriodSelector}>
                      {t('cancel')}
                    </ButtonText>
                    <ButtonText
                      variant="accent"
                      style={{ marginLeft: 16 }}
                      onClick={() => {
                        if (calendarValue) {
                          const periodFormat = 'MMM DD, YYYY';

                          const from = dayjs(calendarValue.start).startOf('day');
                          const to = dayjs(calendarValue.end).endOf('day');

                          const fromPrev = from.add(dayjs(calendarValue.start).diff(calendarValue.end, 'day'), 'day');
                          const toPrev = dayjs(calendarValue.start).endOf('day');

                          applyPeriod({
                            key: 'custom',
                            label: t('custom'),

                            from: from.unix(),
                            to: to.unix(),

                            formatted: `${from.format(periodFormat)} - ${to.format(periodFormat)}`,

                            fromPrev: fromPrev.unix(),
                            toPrev: toPrev.unix(),

                            formattedPrev: `${fromPrev.format(periodFormat)} - ${toPrev.format(periodFormat)}`,

                            days: (dayjs(to).diff(from, 'day') || 0) + 1,
                          });
                        }
                        togglePeriodSelector();
                      }}
                    >
                      {t('apply')}
                    </ButtonText>
                  </DatePickerOptions>
                </>
              )}
            </SelectorContainer>
          )}
        >
          <div>
            <MainPeriodButton
              type="button"
              onClick={togglePeriodSelector}
            >
              <span>{appliedPeriod.formatted}</span>
              <Chevron />
            </MainPeriodButton>
          </div>
        </Popover>
      </Head>
      <CompareDate>{`${t('compare')}: ${appliedPeriod.formattedPrev}`}</CompareDate>
    </Container>
  );
};

const Chevron = styled(ChevronDown)`
    margin-left: 20px;
    path { stroke: ${({ theme }) => theme.onBackground} }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CompareDate = styled.span`
  color: ${({ theme }) => theme.tertiary};
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const List = styled.div`
  border-top: 1px solid ${({ theme }) => theme.onBackgroundLight};
  border-bottom: 1px solid ${({ theme }) => theme.onBackgroundLight};
`;
const SelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    border-radius: 20px;
    box-sizing: border-box;
    ${({ theme }) => `
      background-color: ${theme.background};
      ${theme.boxShadowPrimary}
    `}
`;

const ComparisonDate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
`;
const ComprasionDateFormatted = styled.span`
  ${({ theme }) => `
    font-family: ${theme.primaryFontMedium};
    color: ${theme.onBackground};
  `}
`;
const PeriodLabel = styled.span`
  ${({ theme }) => `
    ${theme.textS}
    font-family: ${theme.primaryFontMedium};
    color: ${theme.onBackgroundLighter};
  `};
  margin-right: 16px;
`;
const MainPeriodButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  ${({ theme }) => `
    ${theme.textM}
    font-family: ${theme.primaryFontBold};
    color: ${theme.primary};
  `}
`;
const DatePickerOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px 16px;
`;

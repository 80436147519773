import React from 'react';
import { useTheme } from 'styled-components';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const LineChartSimple = ({
  tooltipContent,
  data,
  mainLineKey,
  secondaryLineKey,
  xAxisKey,
  tickFormatter,
  tooltipFormatter,
  tooltipLabelFormatter,
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" aspect={6.0 / 3.0}>
      <LineChart
        margin={{
          left: 24, top: 24, bottom: 12, right: 24,
        }}
        data={data}
      >
        <CartesianGrid vertical={false} stroke={theme.onBackgroundLight} />
        <XAxis tickLine={false} axisLine={false} dataKey={xAxisKey} />
        <YAxis
          tickLine={false}
          tickFormatter={tickFormatter}
          axisLine={false}
          orientation="right"
        />
        <Tooltip
          content={tooltipContent}
          formatter={tooltipFormatter}
          labelFormatter={tooltipLabelFormatter}
        />
        <Line strokeWidth="2" type="monotone" dataKey={mainLineKey} stroke={theme.primary} dot />
        <Line type="monotone" dataKey={secondaryLineKey} stroke={theme.tertiary} dot={false} strokeDasharray={[5, 5]} />
      </LineChart>
    </ResponsiveContainer>

  );
};

export default LineChartSimple;

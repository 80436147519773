import { random, range } from 'lodash';
import React from 'react';
import styled from 'styled-components';

export default ({ children }) => (
  <>
    <Content>
      {children}
    </Content>
    <Circle
      top="25px"
      right="25px"
      // right={`${random(0, 200)}px`}
      size={250}
    />
    <Circle
      left="25px"
      top="500px"
      // top={`${random(400, 700)}px`}
      size={200}
    />
    {/* <Circle
      left="0"
      right="0"
      top={`${random(300, 600)}px`}
      size={250}
    /> */}
  </>
);

const Content = styled.div`
    position: relative;
    z-index: 1;
`;
const Circle = styled.div`
    background-color: ${({ theme }) => theme.background};
    position: absolute;
    margin: auto;
    z-index: 0;
    ${({
    top = 'auto',
    right = 'auto',
    bottom = 'auto',
    left = 'auto',
    size = 0,
  }) => `
        top: ${top};
        right: ${right};
        bottom: ${bottom};
        left: ${left};
        height: ${size}px;
        width: ${size}px;
        border-radius:${size / 2}px;
    `};
`;

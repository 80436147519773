import React, { useMemo, useState } from 'react';
import useFetch from 'use-http';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  get,
  isEmpty, isNumber, mapKeys, mergeWith, omit, reverse,
} from 'lodash';

import { useTranslation } from 'react-i18next';
import WidgetBase from '../molecules/WidgetBase';
import LineChartSimple from '../molecules/LineChartSimple';
import { useQuery } from '../hooks/useQuery';
import arrowUp from '../assets/images/arrow-up.svg';
import arrowDown from '../assets/images/arrow-down.svg';
import { usePeriod } from '../context/period';
import { secondsToDuration, calculateDynamic, formattedConsumption } from '../helpers';
import CustomTooltip from '../molecules/CustomTooltip';

const order = ['users', 'charges', 'consumed', 'avgConsumption', 'avgDuration'];

const OverviewWidget = () => {
  const { period: { days } } = usePeriod();

  const aggregation = useMemo(() => {
    if (days > 90) return 'month';
    if (days > 31) return 'week';
    return 'day';
  }, [days]);

  const { query, queryPrev } = useQuery({
    aggregation,
  });
  const { t } = useTranslation();
  const [activeStat, setActiveStat] = useState('consumed');
  const { loading, error, data = {} } = useFetch(`/transactions?${query}`, [query]);
  const { data: dataPrev = {} } = useFetch(`/transactions?${queryPrev}`, [queryPrev]);
  const { chart = [] } = data;
  const { chart: chartPrev = [] } = dataPrev;
  // const totalData = useMemo(() => calculateTotalStats(data), [data]);
  // const totalDataPrev = useMemo(() => calculateTotalStats(dataPrev), [dataPrev]);
  const dynamic = useMemo(() => {
    if (isEmpty(data) || isEmpty(dataPrev)) {
      return {};
    }
    return mergeWith({ ...omit(data, ['chart']) }, { ...omit(dataPrev, ['chart']) }, (objValue = 0, srcValue = 0, key) => {
      if (key === 'chart') return;
      if (isNumber(objValue) && isNumber(srcValue)) {
        return calculateDynamic(srcValue, objValue);
      }
    });
  }, [data, dataPrev]);

  const dataWithPrev = useMemo(() => {
    if (isEmpty(chartPrev) || isEmpty(chart)) return chart;
    const reversedData = reverse([...chart]);
    const reversedDataPrev = reverse([...chartPrev]);
    return reversedData.map((item, i) => {
      const prevData = mapKeys(reversedDataPrev[i], (value, key) => `${key}Prev`);
      let xName;
      // todo: add label with month if month changes
      const date = dayjs(item.date);
      if (item.month) {
        xName = date.format('MMM');
      } else if (item.week) {
        xName = date.format('DD.MM');
      } else {
        xName = date.format('D');
      }
      return {
        xName,
        ...item,
        ...prevData,
      };
    }).reverse();
  }, [chart, chartPrev]);

  return (
    <WidgetBase style={{ padding: 0 }} title={t('overview')} error={error} loading={loading}>
      <Header>
        {!isEmpty(data) && order.map((key) => {
          const dynamicValue = dynamic[key];
          let statValue = data[key];

          if (key === 'avgDuration') statValue = t('h', { value: secondsToDuration(statValue) });
          if (['consumed', 'avgConsumption'].includes(key)) statValue = formattedConsumption(statValue);

          return (
            <HeaderStat onClick={() => setActiveStat(key)} isActive={key === activeStat} key={key}>
              <StatName>{t(key === 'consumed' ? 'consumedWithoutUnits' : key)}</StatName>
              <StatValue>{statValue}</StatValue>
              {isNumber(dynamicValue) && (
              <Dynamic value={dynamicValue}>
                {dynamicValue > 0 && <img alt="+" src={arrowUp} />}
                {dynamicValue < 0 && <img alt="-" src={arrowDown} />}
                {`${Math.abs(dynamicValue)}%`}
              </Dynamic>
              )}
            </HeaderStat>
          );
        })}

      </Header>
      <ChartWrapper>
        <LineChartSimple
          tickFormatter={(tickItem) => {
            if (activeStat === 'avgDuration') {
              return secondsToDuration(tickItem).substr(0, 5);
            }
            if (activeStat === 'consumed' || activeStat === 'avgConsumption') {
              return formattedConsumption(tickItem);
            }
            return tickItem;
          }}
          tooltipContent={<CustomTooltip />}
          tooltipFormatter={(value, name) => {
            name = t(name.replace('Prev', ''));
            if (activeStat === 'avgDuration') {
              value = secondsToDuration(value);
            }
            if (activeStat === 'consumed' || activeStat === 'avgConsumption') {
              value = formattedConsumption(value);
            }
            return [name, ': ', value];
          }}
          tooltipLabelFormatter={(label, props) => {
            const item = get(props, [0]);
            if (!item) return label;
            if (aggregation === 'month' || aggregation === 'week') {
              return `${dayjs(item.payload.date).format('DD MMM')} - ${dayjs(item.payload.date).endOf(aggregation).format('DD MMM')}`;
            }
            return dayjs(item.payload.date).format('DD MMM');

            // return
          }}
          xAxisKey="xName"
          secondaryLineKey={`${activeStat}Prev`}
          mainLineKey={activeStat}
          data={dataWithPrev}
        />
      </ChartWrapper>
    </WidgetBase>
  );
};

const HeaderStat = styled.div`
  border-top: 11px solid transparent;
  padding-left: 12px;
  padding-right: 12px;
  ${({ isActive, theme }) => `
    &:hover {
      cursor: pointer;
      border-top-color: ${isActive ? theme.primary : theme.onBackgroundLight};
    }
    ${isActive ? `border-top-color: ${theme.primary}; cursor: default;` : ''}
   
  `}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 12px;
`;

const ChartWrapper = styled.div`
`;

const Dynamic = styled.span`
  display: flex;
  ${({ theme, value }) => {
    let color;
    if (value > 0) color = theme.primary;
    else if (value < 0) color = theme.secondary;
    return `color: ${color};`;
  }}
`;

const StatName = styled.span`
  margin-top: 16px;
  display: flex;
  font-weight: bold;
`;

const StatValue = styled.span`
  display: flex;
  ${({ theme }) => theme.textM}
  font-weight: bold;
  margin: 1px 0;
`;

export default OverviewWidget;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ButtonPrimary from '../atoms/ButtonPrimary';
import { ReactComponent as SpiriiLogo } from '../assets/images/spirii-logo.svg';
import { useAuth, useProvideAuth } from '../context/auth';

export default () => {
  const { t } = useTranslation();
  const { push, replace } = useHistory();
  const { user, signout } = useAuth();

  return (
    <Header>
      <Logo />
      <div>
        <span>{user?.companyName}</span>
        <div style={{ display: 'flex' }}>
          <ButtonPrimary inverted onClick={() => signout(() => push('/login'))}>
            {t('signout')}
          </ButtonPrimary>
          <ButtonPrimary style={{ marginLeft: 24 }} onClick={() => push('/profile')}>
            {t('seeProfile')}
          </ButtonPrimary>
        </div>
      </div>
    </Header>
  );
};

const Logo = styled(SpiriiLogo)`
    path {
        fill: ${({ theme }) => theme.primary};
    }
`;

const Header = styled.header`
    padding: 48px 0 16px;
    > div {
        display: flex;
        justify-content: space-between;
        font-size: 32px;
        line-height: 150%;
        align-items: flex-end;
        margin-top: 36px;
        ${({ theme }) => `font-family: ${theme.primaryFontBold};`}
    }
`;

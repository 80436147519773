import React from 'react';
import styled from 'styled-components';
import Background from '../atoms/Background';
import { ProvideFilters } from '../context/filters';
import { ProvidePeriod } from '../context/period';
import ErrorBoundary from '../molecules/ErrorBoundary';
import Header from '../molecules/Header';
import ChargerLocationsWidget from '../organisms/ChargerLocationsWidget';
import ChargesPerDaytimeWidget from '../organisms/ChargesPerDaytimeWidget';
import ChargesPerHourWidget from '../organisms/ChargesPerHourWidget';
import ConnectorTypesWidget from '../organisms/ConnectorTypesWidget';
import ContentConfiguration from '../organisms/ContentConfiguration';
import LocationsMapWidget from '../organisms/LocationsMapWidget';
import OverviewWidget from '../organisms/OverviewWidget';
import UsersWidget from '../organisms/UsersWidget';
import EvseStatusWidget from '../organisms/EvseStatusWidget';
import IdTagsWidget from '../organisms/IdTagsWidget';

const Home = () => (
  <ProvideFilters>
    <ProvidePeriod>
      <Body>
        <Header />
        <ContentConfiguration />
        <Grid>
          <Content>
            <ErrorBoundary>
              <OverviewWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <EvseStatusWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <ChargerLocationsWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <UsersWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <IdTagsWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <ConnectorTypesWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <ChargesPerHourWidget />
            </ErrorBoundary>
            <ErrorBoundary>
              <ChargesPerDaytimeWidget />
            </ErrorBoundary>
          </Content>
          <Sidebar>
            <ErrorBoundary>
              <LocationsMapWidget />
            </ErrorBoundary>
          </Sidebar>
        </Grid>
      </Body>
    </ProvidePeriod>
  </ProvideFilters>
);

const Body = styled.div`
  max-width: 1100px;
  margin: auto;
`;
const Grid = styled.section`
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;
const Content = styled.section`
  grid-column: span 2;
`;
const Sidebar = styled.section`
  grid-column: span 1;
`;

export default Home;

import { assign } from 'lodash';
import React, {
  useContext, createContext, useState, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Provider as FetchProvider } from 'use-http';

const firebaseEmailLinkAuth = {
  isAuthenticated: false,
  async signin(cb) {
    if (window.firebase.auth().isSignInWithEmailLink(window.location.href)) {
      console.log('isSignInWithEmailLink', true);
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      window.firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(async (result) => {
          console.log('signInWithEmailLink', result);
          const idToken = await result.user.getIdToken();

          const spiriiUser = await fetch('/api/web/user/signIn', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Accept: 'application/json',
            },
            body: JSON.stringify({ idToken }),

          }).then((res) => res.json()).catch((err) => console.log(err));

          if (spiriiUser?.token) {
            console.log('spiriiUser', spiriiUser);
            window.localStorage.setItem('spirii:token', spiriiUser.token);
            cb(spiriiUser);
          }
          // console.log('ID TOKEN', idToken);
          window.localStorage.removeItem('emailForSignIn');
        })
        .catch(({ code, message }) => {
          console.log({ code, message });
        });
    }
  },
  signout(cb) {
    firebaseEmailLinkAuth.isAuthenticated = false;
    window.localStorage.removeItem('spirii:token');
    window.firebase.auth().signOut().then(() => cb());
  },
};

const authContext = createContext();
export const ConsumeAuth = authContext.Consumer;

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  const { t } = useTranslation();
  const options = {
    headers: { token: auth.user?.token || '' },
    onError: (error) => {
      console.log(error);
      toast.error(error.message || t('unexpectedError'));
    },
  };
  return (
    <FetchProvider
      url="/api/web"
      options={options}
    >
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    </FetchProvider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

const shouldSignOut = (errorType) => ['TokenInvalid', 'TokenExpired', 'TokenRevoked'].includes(errorType);

export function useProvideAuth() {
  const [userIsLoading, setUserIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  const signout = (cb = () => null) => {
    window.localStorage.removeItem('spirii:token');
    window.firebase.auth().signOut().then(() => {
      setUser(null);
      cb();
    });
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (window.firebase.auth().isSignInWithEmailLink(window.location.href)) return;
        const token = window.localStorage.getItem('spirii:token');
        if (!token) return;
        const result = await fetch('/api/web/user', {
          headers: {
            token,
            // token: '41287aa2-f2c0-47ec-9ce1-dcb9146d8bb3',
          },
        }).then((res) => res.json());

        if (shouldSignOut(result.error)) {
          signout();
          throw new Error(result.error);
        }

        setUser(assign({ token }, result));

        if (result.error) {
          throw new Error(result.error);
        }
      } catch (err) {
        toast.error(t(err.message) || t('unexpectedError'));
      } finally {
        setUserIsLoading(false);
      }
    };
    fetchUser();
  }, []);

  const signin = useCallback((cb) => {
    const initSignin = async () => {
      try {
        if (window.firebase.auth().isSignInWithEmailLink(window.location.href)) {
          const email = window.localStorage.getItem('emailForSignIn');
          const signinResult = await window.firebase.auth().signInWithEmailLink(email, window.location.href);
          const idToken = await signinResult.user.getIdToken();
          const spiriiUser = await fetch('/api/web/user/signIn', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              Accept: 'application/json',
            },
            body: JSON.stringify({ idToken }),
          }).then((res) => res.json());
          if (spiriiUser?.token) {
            window.localStorage.setItem('spirii:token', spiriiUser.token);
            setUser(spiriiUser);
            window.localStorage.removeItem('emailForSignIn');
            setUserIsLoading(false);
            cb();
          }
        }
      } catch (err) {
        setUserIsLoading(false);
        console.log('Error signing in', err);
      }
    };
    initSignin();
  }, []);

  return {
    user,
    userIsLoading,
    signin,
    signout,
  };
}

import React from 'react';
import { useTheme } from 'styled-components';

import {
  BarChart, ResponsiveContainer, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, CartesianAxis,
} from 'recharts';

const BarChartSimple = ({
  data, tooltipFormatter, tooltipLabelFormatter, tooltipContent, dataKey,
}) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" aspect={6.0 / 3.0}>
      <BarChart
        data={data}
      >
        <CartesianGrid vertical={false} stroke={theme.onBackgroundLight} />
        <XAxis tickLine={false} axisLine={false} dataKey="x" />
        <YAxis tickLine={false} axisLine={false} orientation="right" />
        <Tooltip
          content={tooltipContent}
          cursor={{ fill: 'transparent' }}
          formatter={tooltipFormatter}
          labelFormatter={tooltipLabelFormatter}
        />
        <Bar
          barSize={30}
          radius={[20, 20, 20, 20]}
          dataKey={dataKey}
          fill={theme.primaryLight}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartSimple;

import dayjs from 'dayjs';
import { flatten } from 'lodash';
import qs from 'qs';
import { useMemo } from 'react';
import { useFilters } from '../context/filters';
import { usePeriod } from '../context/period';

export const useQuery = (params = {}) => {
  const {
    locations,
    evse,
  } = useFilters();

  const {
    period,
  } = usePeriod();

  const query = useMemo(() => qs.stringify({
    locations: locations.map(({ id }) => id),
    connectorTypes: flatten(evse.map(({ ids }) => ids)),
    from: period.from,
    to: period.to,
    tz: dayjs.tz.guess(),
    ...params,
  }, { arrayFormat: 'comma' }), [locations, evse, period.from, period.to, params]);

  const queryPrev = useMemo(() => qs.stringify({
    locations: locations.map(({ id }) => id),
    connectorTypes: flatten(evse.map(({ ids }) => ids)),
    from: period.fromPrev,
    to: period.toPrev,
    tz: dayjs.tz.guess(),
    ...params,
  }, { arrayFormat: 'comma' }), [locations, evse, period.fromPrev, period.toPrev, params]);

  return { query, queryPrev };
};

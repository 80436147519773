import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  isNaN, isNull, isNumber, isUndefined, orderBy, sortBy,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeft } from '../assets/images/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../assets/images/chevron-right.svg';
import arrowUp from '../assets/images/arrow-up.svg';
import arrowDown from '../assets/images/arrow-down.svg';

const oderToArrow = {
  asc: arrowUp,
  desc: arrowDown,
};

const Table = ({
  grid, children, size = 5, defaultOrder = 'desc', defaultOrderBy, order, transformData, statSumKey, statSumKeySuffix = '',
}) => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const [activeOrderBy, setActiveOrderBy] = useState(defaultOrderBy);
  const [activeOrder, setActiveOrder] = useState(defaultOrder);

  const rows = children[1];
  const rowsCount = children[1].length;

  const orderedRows = useMemo(() => rows.sort((rowA, rowB) => {
    const a = rowA[activeOrderBy];
    const b = rowB[activeOrderBy];
    if (a === b) {
      return 0;
    }
    if (isNaN(a) || isUndefined(a) || isNull(a)) {
      return 1;
    }
    if (isNaN(b) || isUndefined(b) || isNull(b)) {
      return -1;
    }
    if (activeOrder === 'asc') {
      return a < b ? -1 : 1;
    }

    return a < b ? 1 : -1;
  }), [rows, activeOrder, activeOrderBy]);

  const rowsToDisplay = orderedRows.slice(page * size, page * size + size);

  const pageStatSum = useMemo(() => {
    if (!statSumKey) return;
    return rowsToDisplay.reduce((sum, row) => sum += (row[statSumKey] || 0), 0).toFixed(2);
  }, [rowsToDisplay]);

  const statSum = useMemo(() => {
    if (!statSumKey) return;
    return rows.reduce((sum, row) => sum += (row[statSumKey] || 0), 0).toFixed(2);
  }, [orderedRows]);

  useEffect(() => {
    setPage(0);
  }, [activeOrder, activeOrderBy]);

  return (
    <section>
      <TableWrapper grid={grid}>
        {children[0].map(({ text, id }, i) => (
          <TableItem key={id}>
            <TableItemHeader
              i={i}
              onClick={() => {
                // if (i === 0) return;
                setActiveOrderBy((currentActiveOrderBy) => {
                  if (currentActiveOrderBy === id) {
                    setActiveOrder((currentActiveOrder) => (currentActiveOrder === 'desc' ? 'asc' : 'desc'));
                    return id;
                  }
                  return id;
                });
              }}
            >
              {text}
              {activeOrderBy === id && <img alt="+" src={oderToArrow[activeOrder]} />}
            </TableItemHeader>
          </TableItem>
        ))}
        {rowsToDisplay.map((item) => order.map((key) => ({
          ...item,
          text: item[key],
          key,
        }))).map((row, i) => row.map(({ text, key }, j) => {
          if (transformData) {
            text = transformData(key, text);
          }
          return (
            <TableItem key={`${i}${j}`}><span title={text}>{text}</span></TableItem>
          );
        }))}
      </TableWrapper>
      {(statSumKey || rowsCount > size) && (
        <Footer>
          {!!statSumKey && (
          <span style={{ display: 'inline-block', marginRight: 20 }}>
            <span title={t(`${statSumKey}PerPageDescription`)}>{`${pageStatSum}${statSumKeySuffix ? ` ${statSumKeySuffix}` : ''}`}</span>
            <span style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>/</span>
            <span title={t(`${statSumKey}TotalDescription`)}>{`${statSum}${statSumKeySuffix ? ` ${statSumKeySuffix}` : ''}`}</span>
          </span>
          )}
          <span>
            {`${page * size + 1}-${Math.min(
              page * size + size,
              rowsCount,
            )} / ${rowsCount}`}
          </span>
          <PaginationButtonWrapper onClick={() => setPage((page) => Math.max(page - 1, 0))}>
            <ArrowLeft disabled={page === 0} />
          </PaginationButtonWrapper>
          <PaginationButtonWrapper
            onClick={() => setPage((page) => Math.min(page + 1, Math.ceil(rowsCount / size) - 1))}
          >
            <ArrowRight disabled={page === Math.ceil(rowsCount / size) - 1} />
          </PaginationButtonWrapper>
        </Footer>
      )}
    </section>
  );
};

const ArrowLeft = styled(ChevronLeft)`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    path {
        stroke: ${({ theme, disabled }) => (disabled ? theme.onBackgroundLighter : theme.onBackground)};
    }
`;

const ArrowRight = styled(ChevronRight)`
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    path {
        stroke: ${({ theme, disabled }) => (disabled ? theme.onBackgroundLighter : theme.onBackground)};
    }
`;

const TableItemHeader = styled.strong`
  /* text-transform: capitalize; */
  /* cursor: ${({ i }) => (i ? 'pointer' : '')};  */
  cursor: pointer;
  user-select: none;
  display: inline-flex;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    font-weight: bold;
`;

const PaginationButtonWrapper = styled.span`
  display: inline-block;
  margin: 0 12px;
  line-height: 0;
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => (grid || '3fr 2fr 2fr 2fr')} ;
  grid-column-gap: 5px;
  text-align: left;
`;

const TableItem = styled.span`
  border-bottom: 1px solid ${({ theme }) => theme.onBackgroundLight};
  padding: 4px 0;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default Table;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEN from './public/locales/en/translation.json';
import translationDA from './public/locales/da/translation.json';
import translationDE from './public/locales/de/translation.json';
import translationSE from './public/locales/se/translation.json';
import translationNO from './public/locales/no/translation.json';
import translationFR from './public/locales/fr/translation.json';
import translationES from './public/locales/es/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
  },
  se: {
    translation: translationSE,
  },
  no: {
    translation: translationNO,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: window.localStorage.getItem('language'),
    fallbackLng: 'en',
    debug: true,
    interpolation: { escapeValue: false },
  });

export default i18n;

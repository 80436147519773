import React from 'react';
import { useTheme } from 'styled-components';

import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush,
} from 'recharts';
import { head, last } from 'lodash';

const BarChartVertical = ({
  xAxisKey, yAxisKey, data, dataKey, ticks, tickFormatter, yTick, tooltipFormatter, onClick, tooltipContent,
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer
      minHeight={`${data.length * 32}px`}
    >
      <BarChart
        margin={{
          left: 24, right: 24, top: 0, bottom: 12,
        }}
        layout="vertical"
        data={data}
      >
        <CartesianGrid horizontal={false} stroke={theme.onBackgroundLight} />
        <XAxis hide axisLine={false} tickFormatter={tickFormatter} domain={[head(ticks), last(ticks)]} ticks={ticks} tickLine={false} dataKey={xAxisKey} type="number" />
        <YAxis
          onClick={onClick}
          tick={yTick}
          axisLine={false}
          tickLine={false}
          width={85}
          dataKey={yAxisKey}
          type="category"
        />
        <Tooltip
          content={tooltipContent}
          cursor={{ fill: 'transparent' }}
          itemStyle={{
            width: 130, overflow: 'hidden', textOverflow: 'ellipsis',
          }}
        />

        <Bar onClick={onClick} cursor="pointer" barCategoryGap={10} barSize={20} dataKey={dataKey} fill={theme.primary} />
      </BarChart>
    </ResponsiveContainer>

  );
};

export default BarChartVertical;

import {
  get, isArray, isEmpty, isNumber, isObject, toLower,
} from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import arrowUp from '../assets/images/arrow-up.svg';
import arrowDown from '../assets/images/arrow-down.svg';
import { formattedConsumption } from '../helpers';

const CustomTooltip = ({
  formatter, labelFormatter, itemStyle, active, payload, label, dynamic = {}, hideName, tooltipLabelFormatter, valueUnit = '',
}) => {
  // console.log('LABEL', label);
  const { t } = useTranslation();
  if (isEmpty(payload)) return null;
  if (active) {
    return (
      <CustomTooltipWrapper style={itemStyle}>
        <div>{labelFormatter ? labelFormatter(label, (payload || [])) : label}</div>
        {(payload || []).map((item) => {
          let dynamicValue = dynamic[label];
          if (isObject(dynamicValue)) {
            dynamicValue = get(dynamicValue, [item.dataKey.replace('Percentage', '')]);
          }
          let value = formatter ? formatter(item.value, item.dataKey) : `${item.value}${valueUnit}`;
          if ((item.dataKey === 'consumed' || item.dataKey === 'avgConsumption') && !isArray(value)) value = formattedConsumption(value);
          return (
            <Values color={item.color} key={item.dataKey}>
              {(!hideName && !formatter) && t(item.dataKey.replace('Percentage', ''))}
              {(!hideName && !formatter) && <Separator>:</Separator>}
              {/* <Strong>{item.dataKey.includes('Percentage') ? `${item.value}%` : item.value}</Strong> */}
              <Strong>{value}</Strong>
              {dynamicValue > 0 && <DynamicArrow alt="+" src={arrowUp} />}
              {dynamicValue < 0 && <DynamicArrow alt="-" src={arrowDown} />}
              {dynamicValue ? `${Math.abs(dynamicValue)}%` : ''}
            </Values>
          );
        })}
      </CustomTooltipWrapper>
    );
  }

  return null;
};

const CustomTooltipWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  padding: 12px 12px;
  border-radius: 20px;
  color: ${({ theme }) => theme.onBackgroundLighter};
  ${({ theme }) => theme.boxShadowPrimary}
`;

const DynamicArrow = styled.img`
  margin-left: 10px;
`;
const Values = styled.div`
  display: flex;
  color: ${({ color }) => color};
`;

const Separator = styled.span`
  display: inline-block;
  margin-right: 5px;
`;
const Strong = styled.strong`
  /* margin-left: 5px; */
`;

export default CustomTooltip;

import fonts from './fonts';
import { lightThemeColors } from './colors';

export const lightTheme = {
  primaryFontRegular: fonts.brandonRegular,
  primaryFontMedium: fonts.brandonMedium,
  primaryFontBold: fonts.brandonBold,
  ...lightThemeColors,
  textS: 'font-size: 16px;',
  textM: 'font-size: 20px;',
  textL: 'font-size: 24px;',
  boxShadowPrimary: 'box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);',
};

import React, { useMemo } from 'react';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import Table from '../molecules/Table';
import WidgetBase from '../molecules/WidgetBase';
import { useQuery } from '../hooks/useQuery';
import { formattedConsumption, secondsToDuration } from '../helpers';

const order = ['user', 'avgDuration', 'count', 'consumed'];

const UsersWidget = () => {
  const { t } = useTranslation();
  const { query } = useQuery();

  const { loading, error, data = [] } = useFetch(`/transactions/user?${query}`, [query]);

  return (
    <WidgetBase title={t('mostActiveUsers')} error={error} loading={loading}>
      <Table
        transformData={(key, value) => {
          if (key === 'avgDuration') return secondsToDuration(value);
          if (key === 'consumed') return formattedConsumption(value);

          return value;
        }}

        order={order}
        defaultOrderBy="consumed"
      >
        {order.map((key) => ({ text: t(key), id: key }))}
        {data}

      </Table>
    </WidgetBase>
  );
};

export default UsersWidget;

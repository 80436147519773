import React from 'react';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import { useQuery } from '../hooks/useQuery';
import Table from '../molecules/Table';
import WidgetBase from '../molecules/WidgetBase';
import { formattedConsumption } from '../helpers';

const order = ['label', 'type', 'firstName', 'lastName', 'charges', 'consumed'];

const IdTagsWidget = () => {
  const { query } = useQuery();
  const { t } = useTranslation();

  const { loading, error, data = [] } = useFetch(`/idtags?${query}`, [query]);

  return (
    <WidgetBase empty={isEmpty(data)} title={t('idTags')} error={error} loading={loading}>
      <Table
        size={10}
        grid="3fr 2fr 2fr 2fr 2fr 2fr"
        transformData={(key, value) => {
          if (key === 'consumed') return formattedConsumption(value);
          return value;
        }}
        order={order}
        defaultOrderBy="consumed"
      >
        {order.map((key) => ({ text: t(key), id: key }))}
        {data}

      </Table>
    </WidgetBase>
  );
};

export default IdTagsWidget;

import React from 'react';
import styled from 'styled-components';

export default (props) => <Container {...props} />;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 16px;
    box-sizing: border-box;
    max-width: 780px;
    width: 100%;
    margin: 12px;
    font-size: 20px;
    ${({ theme }) => `
        background-color: ${theme.background};
        ${theme.boxShadowPrimary}
    `};
    @media (max-width: 900px){
        font-size: 18px;
    }
    @media (max-width: 600px){
        font-size: 16px;
    }
`;

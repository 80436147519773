import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useFetch from 'use-http';
import { Portal, PortalWithState } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { first } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import ButtonPrimary from '../atoms/ButtonPrimary';
import { useAuth, useProvideAuth } from '../context/auth';
import Input from '../atoms/Input';
import InputField from '../molecules/InputField';
import Select from '../molecules/Select';
import ContainerBase from '../atoms/ContainerBase';
import { validateEmail, validateName } from '../helpers';
import { ReactComponent as TrashcanIcon } from '../assets/images/trashcan.svg';
import Loader from '../atoms/Loader';
import { useError } from '../hooks/useError';

const Cred = ({ label, value, onClick }) => {
  const CredContainer = (onClick ? styled.button : styled.span)`
    border: none;
    background: none;
    display: flex;
    padding: 0px;
    color: ${({ theme }) => theme.onBackground};
    font-family: ${({ theme }) => theme.primaryFontRegular};
    font-size: 20px;
  `;
  return (
    <CredContainer>
      <span style={{ fontWeight: 'bold', marginRight: 8 }}>{`${label}:`}</span>
      <span>{value}</span>
    </CredContainer>
  );
};

const UserListItem = ({
  name, email, role, id, isAdmin, onUserDelete = () => {}, ...props
}) => {
  const {
    delete: del, loading, response, error,
  } = useFetch();

  const { t } = useTranslation();
  const [isMouseOver, setMouseOver] = useState(false);

  const deleteUser = async () => {
    await del(`/user/${id}`);
    if (response.ok) return onUserDelete();
  };
  return (
    <UserItem
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      id={id}
      isLoading={loading}
      {...props}
    >
      <Span>{role}</Span>
      <Span>{name}</Span>
      <Span>{email}</Span>
      <Span style={{ textAlign: 'right', position: 'relative', paddingRight: 30 }}>
        {loading && <Loader style={{ height: 24, width: 24, position: 'absolute' }} />}
        {isMouseOver && id && isAdmin && !loading && <DeleteIcon onClick={deleteUser} />}
      </Span>
    </UserItem>
  );
};
const Span = styled.td`
    /* display: flex; */
    /* flex: 1; */
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px 5px;
    border-top: 1px solid ${({ theme, first }) => (first ? 'transparent' : theme.onBackgroundLight)};
`;
const UserItem = styled.tr`
    /* display: flex; */
    align-items: center;
    ${({ bold, theme }) => bold && `font-family: ${theme.primaryFontBold};`}
    ${({ isLoading }) => isLoading && 'opacity: .5;'}
    /* @media (max-width: 900px){
        font-size: 16px;
    }
    @media (max-width: 600px){
        font-size: 14px;
    } */
`;
const DeleteIcon = styled(TrashcanIcon)`
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -12px;
    height: 24px;
    width: 24px;
    path { 
        ${({ theme }) => `fill: ${theme.onBackground};`}
    }
    :hover{
        opacity: .5;
    }
`;

const UserCreationModal = ({ close, onUserCreated = () => {} }) => {
  const { t } = useTranslation();

  const { post, response, loading } = useFetch();

  const roles = useMemo(() => [
    {
      key: 'user',
      label: t('user'),
    }, {
      key: 'admin',
      label: t('admin'),
    },
  ], []);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(first(roles));

  const submit = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (!validateEmail(email) || !validateName(username)) return;

    await post('user/add', { email, name: username, role: role.key });
    if (response.ok) onUserCreated();
  };

  return (
    <UserCreationContainer
      aria-label="close"
      onClick={({ target: { ariaLabel } }) => {
        if (ariaLabel === 'close' && !loading) close();
      }}
    >

      <Form onSubmit={submit}>
        <BorderBox style={{ padding: '0 16px 16px' }}>
          <InputField value={username} onChange={setUsername} label={t('name')} maxLength={30} />
          <InputField value={email} onChange={setEmail} label={t('email')} style={{ margin: '4px 0 24px' }} />
          <Select
            style={{ alignSelf: 'flex-start' }}
            label={t('role')}
            options={roles}
            activeOption={role}
            onOptionClick={setRole}
          />
        </BorderBox>

        <AddButton
          loading={loading}
          disabled={!validateEmail(email) || !validateName(username)}
          type="submit"
        >
          {t('add')}
        </AddButton>
      </Form>
    </UserCreationContainer>
  );
};

const AddButton = styled(ButtonPrimary)`
    align-self: flex-end;
    margin-top: 32px; 
    padding-left: 32px;
    padding-right: 32px;
`;
const UserCreationContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 16px;
    box-sizing: border-box;
    max-width: 480px;
    width: 100%;
    margin: 12px;
    ${({ theme }) => `
        background-color: ${theme.background};
        ${theme.boxShadowPrimary}
    `};
`;

export default () => {
  const { t } = useTranslation();
  const languages = useMemo(() => [
    {
      key: 'en',
      label: t('english'),
    },
    {
      key: 'da',
      label: t('danish'),
    },
    {
      key: 'de',
      label: t('german'),
    },
    {
      key: 'se',
      label: t('swedish'),
    },
    {
      key: 'no',
      label: t('norwegian'),
    },
    {
      key: 'fr',
      label: t('french'),
    },
    {
      key: 'es',
      label: t('spanish'),
    },
  ], []);
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [language, setLanguage] = useState(languages.find(({ key }) => key === window.localStorage.getItem('language')) || first(languages));

  const {
    get, response, loading, error,
  } = useFetch('/users', { cachePolicy: 'no-cache' });

  const { push } = useHistory();

  const fetchUsers = async () => {
    const fetchedUsers = await get();
    if (response.ok) setUsers(fetchedUsers);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const isAdmin = user.role === 'admin';

  return (
    <ContainerBase>
      <ButtonPrimary
        inverted
        onClick={() => push('/')}
        style={{ alignSelf: 'flex-start' }}
      >
        {t('dashboard')}
      </ButtonPrimary>
      <Title>{t('welcomeWithName', { name: user.companyName })}</Title>
      <BorderBox style={{ padding: 24 }}>
        <Cred label={t('name')} value={user.name} />
        <Cred label={t('email')} value={user.email} />
        <Select
          style={{ alignSelf: 'flex-start' }}
          label={t('language')}
          options={languages}
          activeOption={language}
          onOptionClick={(option) => {
            setLanguage(option);
            window.localStorage.setItem('language', option.key);
            window.location.reload();
          }}
        />
      </BorderBox>
      <UsersTitle>{t('users')}</UsersTitle>
      <BorderBox>
        <table style={{
          marginLeft: 16, marginRight: 16, borderCollapse: 'collapse', borderSpacing: 0,
        }}
        >
          <tbody>
            <UserListItem name={t('name')} email={t('email')} role={t('role')} bold first />
            {users.map((u) => (
              <UserListItem
                onUserDelete={() => {
                  setUsers((currentUsers) => currentUsers.filter((user) => user.id !== u.id));
                }}
                isAdmin={isAdmin}
                key={u.id}
                {...u}
                role={t(u.role)}
              />
            ))}
          </tbody>
        </table>

      </BorderBox>
      {isAdmin && (
      <PortalWithState>
        {({
          openPortal, closePortal, isOpen, portal,
        }) => (
          <>
            <ButtonPrimary onClick={openPortal} style={{ alignSelf: 'flex-end', marginTop: 24 }}>
              {t('addNewUser')}
            </ButtonPrimary>
            {portal(<UserCreationModal
              close={closePortal}
              onUserCreated={() => {
                fetchUsers();
                closePortal();
              }}
            />)}
          </>
        )}
      </PortalWithState>
      )}

    </ContainerBase>
  );
};

const BorderBox = styled.tbody`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.primary};
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;
const Title = styled.h1`
    margin: 40px 0 48px;
    /* @media (max-width: 900px) {
        margin: 24px 0 28px;
        font-size: 28px;
    }
    @media (max-width: 600px) {
        margin: 16px 0 18px;
    } */
`;
const UsersTitle = styled.span`
    margin: 24px 0 12px; 
    font-family: ${({ theme }) => theme.primaryFontBold};
`;

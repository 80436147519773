import React from 'react';
import BodyBase from '../atoms/BodyBase';
import LoginForm from '../organisms/LoginForm';

function LoginPage() {
  return (
    <BodyBase>
      <LoginForm />
    </BodyBase>
  );
}

export default LoginPage;

import React from 'react';
import { useTheme } from 'styled-components';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip,
} from 'recharts';
import { isEmpty } from 'lodash';

const Tick = (props) => {
  const {
    background, index, radius, cx, cy, payload: { value },
  } = props;

  const x = (radius + 30) * Math.cos(Math.PI / -2 + (2 * index * Math.PI) / 24) + cx;
  const y = (radius + 30) * Math.sin(Math.PI / -2 + (2 * index * Math.PI) / 24) + cy;
  return (
    <>
      <rect
        {...props}
        y={y}
        x={x}
        fill={background}
        textAnchor="middle"
        width="46px"
        height="24px"
        transform="translate(-23, -14)"
        rx="10px"
      />
      <text {...props} y={y} x={x} dominantBaseline="middle" textAnchor="middle">
        {value < 10 ? `0${value}` : value}
      </text>
    </>
  );
};

const RadarChartSimple = ({
  polarAngleAxisDataKey, data, radars, tooltipContent, tooltipLabelFormatter,
}) => {
  const theme = useTheme();
  if (isEmpty(data)) return null;

  return (
    <ResponsiveContainer width="100%" aspect={1.0 / 1.0}>
      <RadarChart radius="100%" data={data}>
        {!isEmpty(radars) && <PolarGrid gridType="circle" stroke={theme.onBackgroundLightest} />}
        <PolarAngleAxis
          radius={0.5}
          tick={<Tick background={theme.backgroundDarker} />}
          dataKey={polarAngleAxisDataKey}
        />
        <PolarRadiusAxis tickCount={4} axisLine={false} angle={90} />
        <Tooltip labelFormatter={tooltipLabelFormatter} content={tooltipContent} />
        {radars.map((r) => <Radar animationDuration={500} key={r.dataKey} strokeWidth={2} stroke={theme.primary} fill="transparent" {...r} fillOpacity={0.6} />)}

      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChartSimple;

import React, { useMemo, useState } from 'react';
import useFetch from 'use-http';
import styled, { useTheme } from 'styled-components';

import dayjs from 'dayjs';
import {
  get,
  isEmpty,
  isNumber,
  mapKeys,
  mergeWith,
  reverse,
  xor,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import WidgetBase from '../molecules/WidgetBase';
import LineChartSimple from '../molecules/LineChartSimple';
import { useQuery } from '../hooks/useQuery';
import arrowUp from '../assets/images/arrow-up.svg';
import arrowDown from '../assets/images/arrow-down.svg';
import { usePeriod } from '../context/period';
import RadarChartSimple from '../molecules/RadarChartSimple';
import CustomTooltip from '../molecules/CustomTooltip';
import { calculateTotalStats, calculateDynamic, formattedConsumption } from '../helpers';

const statsList = ['count', 'users', 'consumed'];
const units = ['numbers', 'percentage'];

const iToColor = (theme, i) => {
  if (i === 0) return theme.primary;
  if (i === 1) return theme.tertiary;
  if (i === 2) return theme.secondary;
};

const ChargesPerHourWidget = () => {
  const { query, queryPrev } = useQuery();
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStats, setActiveStats] = useState(['count']);
  const [activeUnit, setActiveUnit] = useState('numbers');
  const {
    loading,
    error,
    data = [],
  } = useFetch(`/transactions/hourOfDay?${query}`, [query]);
  const { data: dataPrev = [] } = useFetch(`/transactions/hourOfDay?${queryPrev}`, [queryPrev]);

  const totalStats = useMemo(() => calculateTotalStats(data), [data]);
  const dataWithPercentage = useMemo(() => {
    if (isEmpty(totalStats)) return [];
    return data.map((item) => {
      statsList.forEach((key) => {
        item[`${key}Percentage`] = Number(
          ((100 / totalStats[key]) * item[key]).toFixed(2),
        );
      });
      return item;
    });
  }, [totalStats]);

  const dynamic = useMemo(() => {
    if (isEmpty(data) || isEmpty(dataPrev)) {
      return {};
    }

    return data.reduce((total, d, i) => ({ ...total, [d.hour]: statsList.map((key) => calculateDynamic(dataPrev[i][key], d[key])).reduce((total, dyn, i) => ({ ...total, [statsList[i]]: dyn }), {}) }), {});
  }, [data, dataPrev]);

  return (
    <WidgetBase
      style={{ paddingBottom: 0 }}
      title={t('chargesPerHour')}
      error={error}
      loading={loading}
    >
      <NumbersPercentsSwitch>
        {units.map((key) => (
          <StatsListItem
            isActive={activeUnit === key}
            onClick={() => setActiveUnit(key)}
            key={key}
          >
            {t(key)}
          </StatsListItem>
        ))}
      </NumbersPercentsSwitch>
      <StatsList>
        {statsList.map((key, i) => (
          <StatsListItem
            color={iToColor(theme, i)}
            isActive={activeStats.includes(key)}
            onClick={() => setActiveStats((activeStats) => xor(activeStats, [key]))}
            key={key}
          >
            {t(key)}
          </StatsListItem>
        ))}
        {statsList.map((key) => {
          let value = totalStats[key];
          if (key === 'consumed') value = formattedConsumption(value);
          return (
            <span key={key}>{value}</span>
          );
        })}
      </StatsList>
      <Total />
      <ChartWrapper>
        <RadarChartSimple
          radars={activeStats.map((key, i) => ({
            name: t(key),
            dataKey: activeUnit === 'numbers' ? key : `${key}Percentage`,
            stroke: iToColor(theme, i),
          }))}
          tooltipLabelFormatter={(label) => `${label}:00`}
          tooltipContent={<CustomTooltip valueUnit={activeUnit === 'percentage' ? '%' : ''} dynamic={dynamic} />}
          data={dataWithPercentage}
        />
      </ChartWrapper>
    </WidgetBase>
  );
};

const StatsListItem = styled.span`
  user-select: none;
  cursor: pointer;
  ${({ isActive, color }) => {
    if (isActive) {
      return `font-weight: bold; color: ${color};`;
    }
  }}
`;
const StatsList = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  justify-items: center;
  align-items: center;
  margin: 4px auto 15px;
`;

const NumbersPercentsSwitch = styled(StatsList)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0;
  user-select: none;

`;
const Total = styled.div`

`;

const ChartWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.onBackgroundLight};
`;

export default ChargesPerHourWidget;

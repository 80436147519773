import {
  chunk, get, isEmpty, isNumber, mergeWith, range, xor,
} from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import useFetch from 'use-http';
import qs from 'qs';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../hooks/useQuery';
import BarChartSimple from '../molecules/BarChartSimple';
import WidgetBase from '../molecules/WidgetBase';
import CustomTooltip from '../molecules/CustomTooltip';
import { calculateDynamic } from '../helpers';

const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const transformDataForChart = (data = []) => chunk(data, 3).map((group = []) => group.reduce((newData, current) => mergeWith(newData, current, (objValue = 0, srcValue = 0, key) => {
  if (key === 'hour') return srcValue + 1;
  return objValue + srcValue;
}), {})).map(({ count, hour }) => ({ x: hour, charges: count }));

const ChargesPerDaytimeWidget = () => {
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const { query, queryPrev } = useQuery({ days: selectedWeekdays.sort((a, b) => a - b) });
  const { t } = useTranslation();

  const { loading, error, data = [] } = useFetch(`/transactions/hourOfDay?${query}`, [query]);
  const { data: dataPrev = [] } = useFetch(`/transactions/hourOfDay?${queryPrev}`, [queryPrev]);

  const dataForChart = useMemo(() => transformDataForChart(data), [data]);
  const dataForChartPrev = useMemo(() => transformDataForChart(dataPrev), [dataPrev]);

  const dynamic = useMemo(() => {
    if (isEmpty(dataForChart) || isEmpty(dataForChartPrev)) {
      return {};
    }

    return dataForChart.reduce((total, d, i) => ({ ...total, [d.x]: calculateDynamic(dataForChartPrev[i].charges, d.charges) }), {});
  }, [dataForChart, dataForChartPrev]);

  return (
    <WidgetBase title={t('chargesPerDayAndTime')} loading={loading} error={error}>
      <Weekdays>
        {range(0, 7).map((n) => (
          <Weekday
            active={selectedWeekdays.includes(n)}
            onClick={() => setSelectedWeekdays((current) => xor(current, [n]))}
            key={n}
          >
            {t(weekdays[n])}
          </Weekday>
        ))}
      </Weekdays>
      <BarChartSimple
        tooltipContent={<CustomTooltip name={t('charges')} dynamic={dynamic} />}
        data={dataForChart}
        tooltipLabelFormatter={(label) => `${label}:00`}
        dataKey="charges"
      />
    </WidgetBase>
  );
};

const Weekdays = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 24px;
  justify-items: center;
  align-items: center;
  margin: 0 auto 24px;
  user-select: none;
`;

const Weekday = styled.span`
  border-bottom: 1px solid transparent;
  ${({ active, theme }) => active && `
    font-family: ${theme.primaryFontBold};
    color: ${theme.onBackground};
    border-bottom-color: ${theme.onBackground};
  `}
  &:hover {
    cursor: pointer;
  }
`;

export default ChargesPerDaytimeWidget;

import {
  flatten, get, isEmpty, isNumber, last,
} from 'lodash';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import useFetch from 'use-http';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'recharts';
// import mapboxgl from 'mapbox-gl';

import { useQuery } from '../hooks/useQuery';
import WidgetBase from '../molecules/WidgetBase';
import mapPinSpirii from '../assets/images/map-pin-spirii.png';
import BarChartVertical from '../molecules/BarChartVertical';
import CustomTooltip from '../molecules/CustomTooltip';
import { formattedConsumption } from '../helpers';

window.mapboxgl.accessToken = 'pk.eyJ1IjoiZGFuaWVsZHJlamVyIiwiYSI6ImNra3NpNDZsZDBzajkydm82cDA3ZmtpNXkifQ.B14Ega5HiI7Wele1EoOJSg';

const locationToFeature = (location) => ({
  type: 'Feature',
  properties: {
    id: location.id,
    name: location.name,
    consumed: location.consumed,
    address: location.address,
    iconUrl: mapPinSpirii,
    description: `<div>${location.name}</div><div>${location.address}</div><div><strong>${formattedConsumption(location.consumed)}</strong></div>`,
  },
  geometry: {
    type: 'Point',
    coordinates: [location.lng, location.lat],
  },

});

function calculateTicks(min, max, tickCount) {
  const span = max - min;
  let step = Math.pow(10, Math.floor(Math.log(span / 2) / Math.LN10));
  // console.log('STEP', step, span, tickCount, step);

  // if (span > 20000) {
  //   step = Math.max(10000, step);
  // }
  // if (span > 40000) {
  //   step = Math.max(20000, step);
  // }
  const err = tickCount / span * step;

  // Filter ticks to get closer to the desired count.
  if (err <= 0.15) step *= 10;
  else if (err <= 0.35) step *= 5;
  else if (err <= 0.75) step *= 2;

  // Round start and stop values to step interval.
  const tstart = Math.ceil(min / step) * step;
  const tstop = Math.ceil(max / step) * step + step * 0.5;
  const ticks = [];

  // now generate ticks
  for (let i = tstart; i < tstop; i += step) {
    ticks.push(i);
  }
  return ticks;
}

const Ticks = ({ ticks }) => (
  <TicksWrapper>
    {ticks.map((t) => <span key={t}>{t > 500 ? `${t / 1000}k` : t}</span>)}
  </TicksWrapper>
);

const LocationsMapWidget = () => {
  const { query } = useQuery();
  const mapRef = useRef(null);
  const [map, setMap] = useState();
  const [mapLoaded, setMapLoaded] = useState(false);
  // const nameRef = useRef(null);
  const theme = useTheme();
  // const addressRef = useRef(null);
  // const consumedRef = useRef(null);
  const { t } = useTranslation();
  const [ticks, setTicks] = useState([]);
  const [userZoom, setUserZoom] = useState(12);

  const { loading, error, data = [] } = useFetch(`/locations?${query}`, [query]);

  const filteredData = useMemo(() => {
    if (isEmpty(data)) return [];
    const filtered = data.filter((l) => isNumber(l.consumed) && l.consumed >= 0 && l.lat && l.lng);
    const max = filtered[0].consumed;
    const min = 0;
    const ticks = calculateTicks(min, max, 5);
    setTicks(ticks);
    // const maxTick = last(ticks);
    // data.forEach((item) => item.consumedPercents = (item.consumed / maxTick) * 100);
    // console.log('ticks', ticks);
    // console.log('max', max);
    return filtered;
  }, [data]);

  useEffect(() => {
    const m = new window.mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/danieldrejer/ckgkpmc8z0gaa19ljst6igvfv',
      center: [9.5018, 56.2639],
      zoom: 4,
    });

    m.on('load', () => {
      setMap(m);

      m.addControl(new window.mapboxgl.NavigationControl());

      m.on('click', 'clusters', (e) => {
        const features = m.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        m.getSource('locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            m.easeTo({
              center: features[0].geometry.coordinates,
              zoom,
            });
          },
        );
      });

      const popup = new window.mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });
      m.on('mouseenter', 'unclustered-point', (e) => {
      // Change the cursor style as a UI indicator.
        m.getCanvas().style.cursor = 'pointer';

        const coordinates = e.features[0].geometry.coordinates.slice();
        const { description } = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setOffset(35).setLngLat(coordinates).setHTML(description).addTo(m);
      });
      // m.on('mouseenter', 'clusters', () => {
      //   m.getCanvas().style.cursor = 'pointer';
      // });
      // m.on('mouseleave', 'clusters', () => {
      //   m.getCanvas().style.cursor = '';
      // });
      m.on('mousemove', (e) => {
        const features = m.queryRenderedFeatures(e.point);
        if (!['unclustered-point', 'clusters'].includes(get(features, '[0].layer.id'))) {
          m.getCanvas().style.cursor = '';
          popup.remove();
        } else {
          m.getCanvas().style.cursor = 'pointer';
        }
      });

      m.loadImage(
        mapPinSpirii,
        (error, image) => {
          if (error) throw error;
          m.addImage('spirii-pin', image);
          setMapLoaded(true);
        },
      );
    });
  }, []);

  useEffect(() => {
    if (isEmpty(filteredData)) return;
    if (!mapLoaded) return;

    const locationFeatures = filteredData.map(locationToFeature);
    const existingSource = map.getSource('locations');
    const data = {
      type: 'FeatureCollection',
      features: locationFeatures,
    };
    existingSource ? existingSource.setData(data) : map.addSource('locations', {
      type: 'geojson',
      data,
      cluster: true,
      clusterMinPoints: 2,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      generateId: true,
    });

    // if (existingSource) {
    //   map.removeLayer('clusters');
    //   map.removeLayer('cluster-count');
    //   map.removeLayer('unclustered-point');
    // }

    if (!existingSource) {
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'locations',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': theme.secondary,
          'circle-radius': 20,
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'locations',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-allow-overlap': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,

        },

        paint: {
          'text-color': theme.onSecondary,
        },
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'locations',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'spirii-pin',
          'icon-anchor': 'bottom',
          'icon-size': 0.3,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-ignore-placement': true,
        },
      });
    }
    // Add a symbol layer

    const bounds = new window.mapboxgl.LngLatBounds();

    locationFeatures.forEach((feature) => {
      bounds.extend(feature.geometry.coordinates);
    });

    map.fitBounds(bounds, { padding: 20 });
  }, [filteredData, mapLoaded]);

  return (
    <WidgetBase style={{ padding: 0 }} title={t('yourLocations')} error={error} loading={loading}>
      <RelativeWrapper>
        {/* <LocationInfo>
          <span ref={nameRef} />
          <strong ref={consumedRef} />
        </LocationInfo> */}
        <MapContainer ref={mapRef} />
      </RelativeWrapper>
      <LocationBarsWrapper>
        <LocationBarsContainer className="custom-scrollbar">
          {/* {filteredData.map((l) => (
          <LocationConsumed key={l.id}>
            <Name>{l.name}</Name>
            <Bar width={l.consumedPercents} />
          </LocationConsumed>
        ))} */}

          <BarChartVertical
            data={filteredData}
            tooltipContent={<CustomTooltip hideName />}
            onClick={(label, index) => {
              const {
                lat, lng, name, consumed,
              } = filteredData[index];
              // nameRef.current.textContent = `${name}: `;

              map.flyTo({
                center: [
                  lng,
                  lat,
                ],
                zoom: 16,
                speed: 4,

                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
              });
            }}
            dataKey="consumed"
            yAxisKey="name"
            xAxisKey="consumed"
            ticks={ticks}
            yTick={<CustomizedAxisTick />}
          />
        </LocationBarsContainer>
      </LocationBarsWrapper>
      <Ticks ticks={ticks} />

    </WidgetBase>
  );
};

const CustomizedAxisTick = ({
  y, payload,
}) => {
  let text = payload.value;
  if (text.length > 11) {
    text = `${text.substr(0, 11)}...`;
  }
  return (
    <Text
      textAnchor="start"
      verticalAnchor="middle"
      cursor="pointer"
      x={0}
      y={y}
    >
      {text}
    </Text>
  );
};

const MapContainer = styled.div`
  height: 226px;
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const LocationBarsContainer = styled.div`
  display: grid;
  row-gap: 10px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
`;
const LocationBarsWrapper = styled.div`
  padding: 20px 20px 0px;
`;

const LocationInfo = styled.div`
    position: absolute;
    top: 5px;
    margin: 0 5px;
    max-width: 100%;
    z-index: 100;
    background: white;
    padding: 0 5px;
    border-radius: 5px;

`;

const LocationConsumed = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    column-gap: 15px;
`;
const Name = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    `;
const TicksWrapper = styled.div`
    display: inline-grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    justify-items: center;
    margin-left: 113px;
    margin-right: 40px;
  `;

const Bar = styled.div`
  height: 20px;
  ${({ width, theme }) => `
      width: ${width}%;
      background-color: ${theme.secondary};
    `}
`;

export default LocationsMapWidget;

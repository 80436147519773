import React, { useMemo, useState } from 'react';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import Table from '../molecules/Table';
import WidgetBase from '../molecules/WidgetBase';
import { useQuery } from '../hooks/useQuery';
import { secondsToDuration } from '../helpers';
import { useInterval } from '../hooks/useInterval';

const order = ['evseId', 'name', 'status', 'activePower', 'duration'];

const EvseStatusWidget = () => {
  const { t } = useTranslation();
  const { query } = useQuery();
  const [updateAt, setUpdateAt] = useState(() => Date.now());

  useInterval(() => {
    setUpdateAt(Date.now());
  }, 15000);

  const {
    loading, error, data = [],
  } = useFetch(`/evseStatus?${query}`, { cachePolicy: 'no-cache' }, [query, updateAt]);

  return (
    <WidgetBase title={t('evseStatus')} error={error} loading={loading}>
      <Table
        grid="3fr 3fr 3fr 2fr 2fr"
        size={10}
        statSumKey="activePower"
        statSumKeySuffix="kW"
        transformData={(key, value) => {
          if (key === 'duration') return secondsToDuration(value);
          if (key === 'activePower') {
            return isNumber(value) ? `${value} kW` : '';
          }
          if (key === 'status') {
            return (
              <StatusWithIndicator>
                <StatusIndicator status={value} />
                {`${t(value)}`}
              </StatusWithIndicator>
            );
          }
          return value;
        }}
        order={order}
        defaultOrderBy="duration"
        defaultOrder="asc"
      >
        {order.map((key) => ({ text: t(key), id: key }))}
        {data}

      </Table>
    </WidgetBase>
  );
};

const StatusWithIndicator = styled.span`
  display: flex;
  align-items: center;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 10px;
  ${({ status, theme }) => {
    let color = '#8B9093';
    switch (status) {
      case 'Available':
        color = '#6CD647';
        break;
      case 'Charging':
        color = theme.primary;
        break;
      case 'Faulted':
        color = '#FF0000';
        break;
      case 'Preparing':
      case 'SuspendedEV':
      case 'SuspendedEVSE':
      case 'Finishing':
        color = theme.tertiary;
        break;

      default:
        break;
    }
    return `background-color: ${color};`;
  }}
`;

export default EvseStatusWidget;

import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const ButtonPrimary = ({
  children, onClick, loading, inverted, ...props
}) => (
  <Button
    type="button"
    onClick={onClick}
    inverted={inverted}
    isLoading={loading}
    {...props}
  >
    {loading ? <SLoader inverted={inverted} /> : children}
  </Button>
);

const Button = styled.button`
    ${({ theme, inverted }) => `
        background-color: ${inverted ? theme.background : theme.primary};
        color: ${inverted ? theme.onBackground : theme.onPrimary};
        border: 1px solid ${inverted ? theme.onBackgroundLight : 'transparent'};
        font-family: ${theme.primaryFontMedium};
    `}
    box-sizing: border-box;
    font-size: 20px;
    padding: 0 18px;
    height: 46px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    :disabled {
        background-color: ${({ theme }) => theme.backgroundDarkest};
        color: ${({ theme }) => theme.onBackgroundLighter};
        cursor: default;
    }
    :disabled:hover{
        opacity: 1;
    }
    :hover{
        ${({ isLoading }) => isLoading && 'opacity: 1;'}
    }
`;

const SLoader = styled(Loader)`
    stroke: ${({ theme, inverted }) => (inverted ? theme.primary : theme.onPrimary)};
    height: 24px;
    width: 24px;
`;

export default ButtonPrimary;

import { isNumber, mergeWith } from 'lodash';

const secondsToDuration = (sec_num) => {
  if (!sec_num) return '';
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

  if (hours < 10) { hours = `0${hours}`; }
  if (minutes < 10) { minutes = `0${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }
  return `${hours}:${minutes}:${seconds}`;
};

const calculateTotalStats = (stats = []) => {
  const sum = stats.reduce((sum, curr, i) => mergeWith(sum, curr, (objValue = 0, srcValue = 0, key) => {
    if (isNumber(objValue) && isNumber(srcValue)) {
      return objValue + srcValue;
    }
  }), {});

  Object.keys(sum).forEach((key) => {
    if (['avgDuration', 'avgConsumption'].includes(key)) {
      sum[key] = Number((sum[key] / stats.length).toFixed(2));
    }
  });

  return sum;
};

export {
  secondsToDuration,
  calculateTotalStats,
};

export const validateEmail = (email) => {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
};
export const validateName = (name) => {
  const reg = /^[0-9a-zA-Z ]{2,30}$/;
  return reg.test(name);
};

export const calculateDynamic = (prevValue, currentValue) => {
  try {
    const val = Number((100 - (100 / prevValue) * currentValue).toFixed(2));
    if (!val || val === 0) return 0;
    return -val;
  } catch (err) {
    return 0;
  }
};

export const formattedConsumption = (consumption) => {
  if (consumption > 1500000) {
    return `${Math.round(consumption / 10000) / 100} GWh`;
  }
  if (consumption > 1500) {
    return `${Math.round(consumption / 10) / 100} MWh`;
  }

  return `${Math.round(consumption * 100) / 100} kWh`;
};

import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled, { ThemeProvider, useTheme } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { lightTheme } from './modules/themes';
import { ProvideAuth } from './context/auth';
import PrivateRoute from './molecules/PrivateRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import 'react-toastify/dist/ReactToastify.css';
import Background from './atoms/Background';

function App() {
  return (
    <ProvideAuth>
      <Suspense fallback="loading">
        <Router>
          <ThemeProvider theme={lightTheme}>
            <DefaultStyles>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
              />
              <Background>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <PrivateRoute path="/profile">
                    <Profile />
                  </PrivateRoute>
                  <PrivateRoute path="/">
                    <Home />
                  </PrivateRoute>
                </Switch>
              </Background>
            </DefaultStyles>
            <TooltipWithTheme />
          </ThemeProvider>
        </Router>
      </Suspense>
    </ProvideAuth>
  );
}

const TooltipWithTheme = () => {
  const theme = useTheme();
  return (
    <ReactTooltip effect="solid" backgroundColor={theme.primary} />

  );
};

const DefaultStyles = styled.div`
  ${({ theme }) => `
      font-family: ${theme.primaryFontRegular};
      background-color: ${theme.backgroundDarker};
      font-size: 16px;
      line-height: 1.5;
      color: ${theme.onBackground};
    `}
`;

export default App;

/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Loader } from '../assets/images/loader.svg';

export default (props) => <SLoader {...props} />;

const SLoader = styled(Loader)`
    stroke: ${({ theme }) => theme.primary};
`;

import { flatten, xorBy } from 'lodash';
import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react';
import qs from 'qs';

const FiltersContext = createContext(null);

const ConsumeFilters = FiltersContext.Consumer;

const useProvideFilters = () => {
  const [locations, setLocations] = useState([]);
  const [evse, setEvse] = useState([]);

  return {
    locations,
    evse,
    setLocations,
    setEvse,
  };
};

const ProvideFilters = ({ children }) => {
  const filters = useProvideFilters();
  return (
    <FiltersContext.Provider value={filters}>
      {children}
    </FiltersContext.Provider>
  );
};

const useFilters = () => useContext(FiltersContext);

export {
  ProvideFilters,
  ConsumeFilters,
  useFilters,
};

import { includes, sortBy, xorBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import useFetch from 'use-http';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFilters } from '../context/filters';
import { ReactComponent as CheckActive } from '../assets/images/check-outlined-medium-primarylight.svg';
import { ReactComponent as Plus } from '../assets/images/plus-primary.svg';
import { ReactComponent as XOutline } from '../assets/images/x-outlined-small.svg';
import ButtonText from '../atoms/ButtonText';
import InputField from './InputField';

export const supportedEvseList = [
  {
    name: 'Type 1',
    types: ['cType1'],
    ids: [5],
  },
  {
    name: 'Type 2',
    types: ['cType2', 'sType2'],
    ids: [6, 13],
  },
  {
    name: 'CHAdeMO',
    types: ['cG105'],
    ids: [3],
  },
  {
    name: 'CCS',
    types: ['cCCS2'],
    ids: [2],
  },
];

const CheckButton = ({
  label, active,
  onClick = () => {},
  ...props
}) => (
  <ListButton
    onClick={(ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick();
    }}
    type="button"
    {...props}
  >
    <Check>
      {active ? <SCheckActive /> : null}
    </Check>
    <span>{label}</span>
  </ListButton>
);

const SCheckActive = styled(CheckActive)`
  path {
    stroke: ${({ theme }) => theme.primary};
  }
`;

const SelectedFilterButton = ({
  label,
  onClick = () => {},
  ...props
}) => (
  <ListButton
    onClick={(ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick();
    }}
    style={{ justifyContent: 'space-between' }}
    type="button"
    {...props}
  >
    <span>{label}</span>
    <CloseIc>
      <SXOutline style={{
        height: '80%', width: '80%', marginLeft: 0.6,
      }}
      />
    </CloseIc>
  </ListButton>
);

const ListButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    text-align: left;
    margin-top: 12px;
    ${({ theme }) => `
        ${theme.textS};
        font-family: ${theme.primaryFontRegular};
        color: ${theme.onBackground};
    `}
    padding: 0;
`;
const Check = styled.div`
    border: 1px solid ${({ theme }) => theme.onBackground};
    border-radius: 4px;
    overflow: hidden;
    min-height: 24px;
    min-width: 24px;
    max-height: 24px;
    max-width: 24px;
    margin-right: 12px;
`;
const CloseIc = styled.div`
    background-color: ${({ theme }) => theme.onBackgroundLighter};
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    margin-left: 12px;
`;

const AppliedButton = ({
  label,
  ...props
}) => (
  <AppliedButtonContainer {...props}>
    <span>{label}</span>
    <SXOutline style={{ marginLeft: 16 }} />
  </AppliedButtonContainer>
);

const SXOutline = styled(XOutline)`
  path {
    stroke: ${({ theme }) => theme.onPrimary};
  }
`;

const AppliedButtonContainer = styled.button`
    display: flex;
    margin-right: 12px;
    margin-bottom: 8px;
    text-transform: capitalize;
    align-items: center;
    border-radius: 20px;
    padding: 0 8px 0 12px;
    border: 1px solid transparent;
    ${({ theme }) => `
        font-family: ${theme.primaryFontMedium};
        color: ${theme.onPrimary};
        background-color: ${theme.primary};
        ${theme.textM};
    `}
`;

export default () => {
  const { t } = useTranslation();
  const [filtersOpen, toggleFiltersPopover] = useState(false);
  const [tmpSelectedLocations, setTmpLocation] = useState([]);
  const [tmpSelectedEvse, setTmpEvse] = useState([]);
  let { data: locations = [] } = useFetch('/locations', []);
  const [searchValue, setSearchValue] = useState('');
  //   const { data: locations = [] } = useFetch('/locations', []);

  locations = useMemo(() => sortBy(locations, (it) => it.name), [locations]);

  locations = useMemo(() => {
    if (searchValue.length > 0) {
      return locations.filter(({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
    }
    return locations;
  }, [searchValue, locations]);

  const {
    locations: appliedLocations,
    evse: appliedEvse,
    setLocations: applyLocations,
    setEvse: applyEvse,
  } = useFilters();

  // console.log(locations);
  // console.log('!!!!!!!', locations);

  const selectTmpLocation = useCallback((location) => {
    setTmpLocation(xorBy(tmpSelectedLocations, [location], 'id'));
  }, [tmpSelectedLocations]);

  const selectTmpEvse = useCallback((evse) => {
    setTmpEvse(xorBy(tmpSelectedEvse, [evse], 'name'));
  }, [tmpSelectedEvse]);

  return (
    <Container>
      <Popover
        isOpen={filtersOpen}
        positions={['bottom', 'top', 'left', 'right']}
        align="start"
        onClickOutside={() => toggleFiltersPopover(false)}
        content={(
          <FiltersContainer>
            <Main>
              <List>
                <div style={{ display: 'flex', paddingRight: 8 }}>
                  <ListHeader>{t('locations')}</ListHeader>
                  <InputField
                    variant="bordered"
                    value={searchValue}
                    onChange={setSearchValue}
                    placeholder={t('search')}
                  />
                </div>
                {/* <InputField label={t('locations')} value={searchValue} onChange={setSearchValue} /> */}
                <Scrollable className="custom-scrollbar">
                  {locations.map((location, i) => {
                    const active = includes(tmpSelectedLocations, location);
                    return (
                      <CheckButton
                        first={i === 0}
                        onClick={() => {
                          selectTmpLocation(location);
                        }}
                        key={location.id}
                        active={active}
                        label={location.name}
                      />
                    );
                  })}
                </Scrollable>
              </List>
              <List>
                <ListHeader>{t('plugTypes')}</ListHeader>
                <Scrollable className="custom-scrollbar">
                  {supportedEvseList.map((evse, i) => {
                    const active = includes(tmpSelectedEvse, evse);
                    return (
                      <CheckButton
                        onClick={() => selectTmpEvse(evse)}
                        key={evse.name}
                        active={active}
                        label={evse.name}
                      />
                    );
                  })}
                </Scrollable>
              </List>
              <List style={{ border: 'none' }}>
                <ListHeader>{t('selectedFilters')}</ListHeader>
                <Scrollable className="custom-scrollbar">
                  {tmpSelectedEvse.map((evse, i) => (
                    <SelectedFilterButton
                      onClick={() => selectTmpEvse(evse)}
                      key={evse.name}
                      label={evse.name}
                    />
                  ))}
                  {tmpSelectedLocations.map((location, i) => (
                    <SelectedFilterButton
                      onClick={() => selectTmpLocation(location)}
                      key={location.id}
                      label={location.name}
                    />
                  ))}
                </Scrollable>
              </List>
            </Main>
            <Options>
              <ButtonText
                type="button"
                onClick={() => {
                  toggleFiltersPopover(false);
                }}
              >
                {t('cancel')}
              </ButtonText>
              <ButtonText
                style={{ marginLeft: 24 }}
                type="button"
                variant="accent"
                onClick={() => {
                  applyLocations(tmpSelectedLocations);
                  applyEvse(tmpSelectedEvse);
                  toggleFiltersPopover(false);
                }}
              >
                {t('apply')}
              </ButtonText>
            </Options>
          </FiltersContainer>
        )}
      >
        <div>
          <FilterButton
            type="button"
            onClick={() => {
              const newStatus = !filtersOpen;
              if (newStatus) {
                setTmpLocation(appliedLocations);
                setTmpEvse(appliedEvse);
              } else {
                setTmpLocation([]);
                setTmpEvse([]);
              }
              toggleFiltersPopover(newStatus);
            }}
          >
            <span>{t('addFilter')}</span>
            <PlusPrimary />
          </FilterButton>
        </div>
      </Popover>
      {appliedLocations.map((location) => (
        <AppliedButton
          label={location.name}
          key={location.id}
          onClick={() => applyLocations(xorBy(appliedLocations, [location], 'id'))}
        />
      ))}
      {appliedEvse.map((evse) => (
        <AppliedButton
          label={evse.name}
          key={evse.name}
          onClick={() => applyEvse(xorBy(appliedEvse, [evse], 'name'))}
        />
      ))}
    </Container>
  );
};

const PlusPrimary = styled(Plus)`
  margin-left: 16px;
  path {
    stroke: ${({ theme }) => theme.primary};
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;
const FilterButton = styled.button`
    display: flex;
    text-transform: capitalize;
    align-items: center;
    border-radius: 20px;
    padding: 0 8px 0 12px;
    margin-right: 12px;
    ${({ theme }) => `
        font-family: ${theme.primaryFontMedium};
        background-color: ${theme.backgroundDarker};
        border: 1px solid ${theme.onBackground};
        color: ${theme.primary};
        ${theme.textM};
    `}
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 20px;
    box-sizing: border-box;
    ${({ theme }) => `
      background-color: ${theme.background};
      ${theme.boxShadowPrimary}
    `}
`;
const Main = styled.div`
    display: flex;
`;
const Options = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 12px 24px;
    border-top: 1px solid ${({ theme }) => theme.onBackgroundLight};
`;
const List = styled.div`
    border-right: 1px solid ${({ theme }) => theme.onBackgroundLight};
`;
const ListHeader = styled.p`
    color: ${({ theme }) => theme.onBackground};
    margin: 14px 16px;
    font-size: 14px;
    ${({ theme }) => `font-family: ${theme.primaryFontRegular};`}
`;
const Scrollable = styled.div`
    border-top: 1px solid ${({ theme }) => theme.onBackgroundLight};
    overflow-y: scroll;
    height: 192px;
    /* width: 180px; */
    min-width: 180px;
    padding: 4px 16px 16px;
    box-sizing: border-box;
`;

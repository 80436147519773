import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

dayjs.extend(utc);
dayjs.extend(timezone);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

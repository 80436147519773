import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ButtonPrimary from '../atoms/ButtonPrimary';
import ContainerBase from '../atoms/ContainerBase';
import { useAuth } from '../context/auth';
import { validateEmail } from '../helpers';
import InputField from '../molecules/InputField';
import LoadingScreen from '../molecules/LoadingScreen';
import Separator from '../atoms/Separator';
import { ReactComponent as SpiriiLogo } from '../assets/images/spirii-logo.svg';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${process.env.NODE_ENV === 'development' ? 'http://' : 'https://'}${window.location.host}/login`,
  // This must be true.
  handleCodeInApp: true,
};

const {
  READY, VALIDATING, SUCCESS, FAILURE,
} = {
  READY: 1,
  VALIDATING: 2,
  SUCCESS: 3,
  FAILURE: 4,
};

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { t } = useTranslation();

  const { from } = location.state || { from: { pathname: '/' } };
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(READY);
  // const [status, setStatus] = useState(SUCCESS);

  useEffect(() => {
    // if (auth.userIsLoading) return;
    if (auth.user) {
      history.replace(from);
    }
    auth.signin(() => {
      history.replace(from);
    });
  }, [history, from, auth]);

  const handleSubmit = useCallback((ev) => {
    const handleSignin = async () => {
      try {
        ev.preventDefault();
        if (!validateEmail(email)) return;
        setStatus(VALIDATING);
        await window.firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
        setStatus(SUCCESS);
      } catch (err) {
        setStatus(FAILURE);
        console.log('Error handle sign in', err);
      }
    };
    handleSignin();
  }, [email]);

  if (auth.userIsLoading) return <LoadingScreen />;
  if (auth.user) return null;

  return (
    <>
      <Title>{t('welcomeToSpiriiDataPortal')}</Title>
      <Logo />
      <ContainerBase style={{ maxWidth: 530, padding: '48px 64px' }}>
        {status === SUCCESS
          ? (
            <EmailText>
              {t('sentEmailTo1')}
              <span>{` ${email.toLowerCase()} `}</span>
              {t('sentEmailTo2')}
            </EmailText>
          ) : (
            <Form onSubmit={handleSubmit}>
              <InputField
                value={email}
                onChange={setEmail}
                label={t('email')}
              />
              <ButtonPrimary
                style={{ margin: '24px 0' }}
                loading={status === VALIDATING}
                disabled={!validateEmail(email)}
                type="submit"
              >
                {t('signin')}
              </ButtonPrimary>
              <Separator size={2} />
              <span>{t('emailDescription')}</span>
            </Form>
          )}
      </ContainerBase>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    margin-top: 32px;
    text-align: center;
  }
`;
const EmailText = styled.span`
  text-align: center;
  ${({ theme }) => `${theme.textM}`}
  > span {
    color: ${({ theme }) => theme.primary};
    font-family: ${({ theme }) => theme.primaryFontBold};
  }
`;

const Logo = styled(SpiriiLogo)`
  position: absolute;
  z-index: 3;
  top: 48px;
  right: 48px;
  path { fill: ${({ theme }) => theme.primary}; }
`;

const Title = styled.h2`
  position: absolute;
  z-index: 3;
  top: 48px;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  font-size: 32px;

`;

export default LoginForm;

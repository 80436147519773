import React from 'react';
import styled from 'styled-components';

const ButtonText = ({ children, ...props }) => (
  <Button type="button" {...props}>
    <span>{children}</span>
  </Button>
);

const Button = styled.button`
    ${({ theme, variant }) => `
        ${theme.textS}
        font-family: ${theme.primaryFontRegular};
        color: ${variant === 'accent' ? theme.primary : theme.onBackground};
    `};
    background-color: transparent;
    border: none;
`;

export default ButtonText;

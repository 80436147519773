import React from 'react';
import styled from 'styled-components';

const WidgetBase = ({
  children, loading, title, style, empty,
}) => (empty ? null : (
  <>
    {title && <WidgetTitle>{title}</WidgetTitle>}
    <Widget style={style}>
      {children}
    </Widget>
  </>
));

const WidgetTitle = styled.h2`
  text-transform: capitalize;
  margin: 0 0 12px;
  font-size: 32px;
`;

const Widget = styled.div`
  background-color: ${({ theme }) => theme.background};
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ theme }) => theme.boxShadowPrimary}
`;

export default WidgetBase;

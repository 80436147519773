import React, { useRef, useState } from 'react';
import styled from 'styled-components';

export default React.forwardRef(({
  label,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  value,
  variant = 'default',
  style,
  ...props
}, ref) => {
  const inputRef = useRef(null);
  const [focused, setFocus] = useState(false);
  return (
    <InputContainer hasLabel={Boolean(label)} style={style}>
      {label && (
        <Label
          onClick={() => inputRef?.current?.focus()}
          focused={focused || value}
        >
          {label}
        </Label>
      )}
      <Input
        ref={inputRef}
        variant={variant}
        type="text"
        focused={focused || value}
        value={value}
        onChange={({ target: { value: text } }) => {
          onChange(text);
        }}
        onFocus={(ev) => {
          setFocus(true);
          onFocus(ev);
        }}
        onBlur={(ev) => {
          setFocus(false);
          onBlur(ev);
        }}
        {...props}
      />
    </InputContainer>
  );
});

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: ${({ hasLabel }) => (hasLabel ? 20 : 0)}px;
  display: flex;
  align-items: center;
`;
const Label = styled.span`
  position: absolute;
  user-select: none;
  cursor: text;
  font-size: 20px;
  font-family: ${({ theme }) => theme.primaryFontMedium};
  transition: transform 100ms ease-in-out, color 100ms ease-in-out;
  ${({ focused, theme }) => (focused ? `
    transform: translateY(-26px) translateX(-6px) scale(0.8);
    color: ${theme.primary};
  ` : `
    color: ${theme.onBackground};
  `)}
`;
const Input = styled.input`
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  transition: border 100ms ease-in-out;
  background: none;
  ${({ variant, theme, focused }) => {
    if (variant === 'bordered') {
      return `
        border: 1px solid ${focused ? theme.primary : theme.onBackgroundLight};
        font-size: 16px;
        text-indent: 8px;
        height: 28px;
      `;
    }
    return `
        border-bottom: 1px solid ${focused ? theme.primary : theme.onBackgroundLight};
        font-size: 20px;
        height: 36px;
      `;
  }}
  ${({ theme }) => `
    font-family: ${theme.primaryFontRegular};
    caret-color: ${theme.onBackground};
    color: ${theme.onBackground};
  `}
`;

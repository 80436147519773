import React from 'react';
import styled from 'styled-components';
import PeriodSelector from '../molecules/PeriodSelector';
import Filters from '../molecules/Filters';
import CSVDownload from './CSVDownload';

export default () => (
  <Container>
    <Filters />
    <PeriodSelector />
    <CSVDownload />
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid ${({ theme }) => theme.primary};
  padding-top: 24px;
`;

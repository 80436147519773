import { flatten } from 'lodash';
import React from 'react';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';

import { useQuery } from '../hooks/useQuery';
import Table from '../molecules/Table';
import WidgetBase from '../molecules/WidgetBase';
import { formattedConsumption } from '../helpers';

const order = ['name', 'address', 'charges', 'consumed'];

const ChargerLocationsWidget = () => {
  const { query } = useQuery();
  const { t } = useTranslation();

  const { loading, error, data = [] } = useFetch(`/transactions/location?${query}`, [query]);

  return (
    <WidgetBase title={t('mostUsedLocations')} error={error} loading={loading}>
      <Table
        transformData={(key, value) => {
          if (key === 'consumed') return formattedConsumption(value);
          return value;
        }}
        order={order}
        defaultOrderBy="consumed"
      >
        {order.map((key) => ({ text: t(key), id: key }))}
        {data}

      </Table>
    </WidgetBase>
  );
};

export default ChargerLocationsWidget;

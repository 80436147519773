import React, { useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from '../assets/images/chevron-down-outlined-medium-onbackground.svg';

export default ({
  label = '',
  activeOption = {},
  options = [],
  onOptionClick = () => {},
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      isOpen={open}
      positions={['bottom', 'top', 'right', 'left']}
      align="end"
      onClickOutside={() => setOpen(false)}
      content={(
        <ContentContainer>
          {options.map((option) => {
            const active = option.key === activeOption.key;
            return (
              <ListItem
                active={active}
                type="button"
                key={option.key}
                onClick={() => {
                  onOptionClick(option);
                  setOpen(false);
                }}
              >
                {option.label}
              </ListItem>
            );
          })}
        </ContentContainer>
        )}
    >
      <div {...props}>
        <Button
          type="button"
          onFocus={() => setOpen(true)}
        //   onBlur={() => setOpen(false)}
          onClick={() => setOpen(true)}
        >
          <span type="button" style={{ fontWeight: 'bold', marginRight: 8 }}>{`${label}:`}</span>
          <span>{activeOption.label}</span>
          <Chevron />
        </Button>
      </div>
    </Popover>
  );
};

const Chevron = styled(ChevronDown)`
    margin-left: 12px;
    height: 28px;
    width: 28px;
    margin-top: 2px;
    path { stroke: ${({ theme }) => theme.onBackground} }
`;

const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    padding: 0px;
    align-items: center;
    flex-direction: row;
    color: ${({ theme }) => theme.onBackground};
    font-family: ${({ theme }) => theme.primaryFontRegular};
    font-size: 20px;
`;

const ListItem = styled.button`
    display: flex;
    background: none;
    padding: 4px 120px 4px 16px;
    border: none;
    color: ${({ theme, active }) => (active ? theme.primary : theme.onBackground)};
    ${({ theme }) => `
    ${theme.textS}
        font-family: ${theme.primaryFontMedium};
    `}
`;
const ContentContainer = styled.div`
    background-color: ${({ theme }) => theme.background};
    display: flex;
    align-items: flex-start;
    padding: 4px 0;
    ${({ theme }) => theme.boxShadowPrimary};
    flex-direction: column;
    border-radius: 12px;
    box-sizing: border-box;
`;

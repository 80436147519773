import React, { useCallback } from 'react';
import styled from 'styled-components';
import useFetch from 'use-http';
import download from 'downloadjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../hooks/useQuery';
import { ReactComponent as DownloadIcon } from '../assets/images/download.svg';

const CSVDownload = () => {
  const { query } = useQuery();

  const {
    get, response, loading, error,
  } = useFetch(`/transactions/csv?${query}`);

  const { t } = useTranslation();

  const downloadCSV = useCallback(async () => {
    const csv = await get();
    if (response.ok) {
      download(csv, 'spirii-dashboard.csv', 'text/csv');
    } else {
      toast.error(t('couldNotDownloadCSV'));
    }
  }, [query]);

  return (
    <Button data-tip={t('downloadCSV')} disabled={loading} target="__blank" onClick={downloadCSV}>
      <SDownloadIcon />
    </Button>
  );
};

const Button = styled.a`
  display: inline-block;
  cursor: pointer;
  align-self: center;
  margin-left: 22px;
  ${({ disabled }) => {
    if (disabled) {
      return 'pointer-events: none; opacity: 0.5;';
    }
  }}
`;

const SDownloadIcon = styled(DownloadIcon)`
  path {
    stroke: ${({ theme }) => theme.primary};
  }
`;

export default CSVDownload;

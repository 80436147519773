export const lightThemeColors = {
  background: '#FFFFFF',
  backgroundDarker: '#F8F9F9',
  backgroundDarkest: '#e8e8e8',
  onBackgroundLight: '#E4E6E7',
  onBackgroundLighter: '#8B9093',
  onBackgroundLightest: '#F1F3F3',
  onBackground: '#4E5B61',
  primary: '#368B97',
  primaryLight: '#56ABB7',
  onPrimary: '#FFFFFF',
  secondary: '#DC087E',
  onSecondary: '#FFFFFF',
  tertiary: '#DE812E',
};

import React from 'react';
import styled from 'styled-components';

export default (props) => <Separator {...props} />;

const Separator = styled.div`
    height: ${({ size = 1 }) => `${size}px`};
    width: 100%;
    display: flex;
    ${({ theme }) => `
        background-color: ${theme.primary};
    `}
`;

import React from 'react';
import styled from 'styled-components';

export default (props) => <Body {...props} />;

const Body = styled.div`
    min-height: 100vh;
    padding: 64px 0;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 12px;
`;
